@font-face {
    font-family: 'Yellowtail';
    src: url('../../assets/fonts/Yellowtail.ttf') format('truetype');
}

.container {
    color: black;
    background: #fff;
    width: 90%;
    font-family: Calibri;
    font-size: 1.12rem;
    margin: 0 auto;
    padding: 1.8rem;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.campusInfo {
    line-height: 1.5rem;
    text-align: center;
    text-transform: uppercase;
}

.factSheetHeader {
    margin: 2.8rem auto;
    text-align: center;
    font-size: 0.8rem;
}

.calendarYears {
    text-transform: uppercase;
    margin: 0 auto;
    width: 25rem;
}

.tableContainer {
    margin-top: 5rem;

}

.titleLabel {
    margin: 4.37rem auto 0 auto;
    text-align: center;
}

.dataTables {
    margin: 0 auto;
    width: 80%;
}

.subtitleEmployment {
    margin: 0 auto;
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 0.625rem;
}

.dataTables {
    font-size: 1.5rem;
    border-collapse: collapse;
}

.dataTables th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}

.federalLoan {
    margin: 0 auto;
}

.initialsLabel {
    margin-top: 2.5rem;
    margin-left: 8.12rem;
    font-size: 1.12rem;
}

.initialsLabel p {
    margin-top: -0.125rem;
}

.initialsLabel span {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
}

.dateLabel {
    margin-left: 1.5rem;
}

.signatureLabel {
    font-family: 'Yellowtail', sans-serif;
    border: none;
    border-bottom: 1px solid black;
    min-width: 7rem;
    height: 1.875rem;
    cursor: pointer;
    font-size: 1.25rem;
    background-color: #fff;
}


.notSignLabel {
    border: none;
    border-bottom: 1px solid black;
    width: 7rem;
    height: 1.875rem;
    cursor: pointer;
    background-color: #f8f53b;
}


.paragraphs {
    font-size: 1.125rem;
    width: 85%;
    text-align: left;
    margin: 20px auto;
}

.signatureContainer {
    font-size: 1.25rem;
    margin-left: 8.125rem;
}

.signatureContainer span {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
}

.studentSignatures {
    margin-top: 8.125rem;
}

.factSheetInfo {
    font-size: 1.125rem;
    margin: 0 auto;
    width: 85%;
}

.studentRights {
    width: 85%;
    margin: 6.25rem auto;
}

.studentRights h1 {
    text-align: center;
}

.factSheetForm {
    width: 85%;
    margin: 0 auto;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.factSheetForm button {
    margin-left: 1.375rem;
    width: 6.25rem;
    padding: 0.625rem;
    background-color: #4681f4;
    color: #fff ;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
}

.factSheetForm button:disabled {
    cursor: not-allowed;
    background-color: #789ce6; 
}

.factSheetForm label {
    margin-left: 0.5rem;
}

@media only screen and (max-width: 600px) {
    body {
      width: 1000px;
    }
    .container {
      width: 1000px;
    }
}