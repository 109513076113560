@font-face {
    font-family: 'Yellowtail';
    src: url('../../assets/fonts/Yellowtail.ttf') format('truetype');

}

.agentFormContainer {
    color: black;
    display: flex;
    flex-direction: column;
    font-family: Calibri;
}

.formBody {
    margin-top: 5rem;
}

.formContent {
    font-size: 1.25rem;
    margin: 3rem 22rem;
}

.formContent h1 {
    font-size: 1.5rem;
    text-align: center;
}

.formContent h2 {
    font-size: 1.25rem;
}

.topics {
    font-weight: bold;
}

.agentSignatureLabel {
    font-family: 'Yellowtail', sans-serif;
    border: none;
    border-bottom: 1px solid black;
    min-width: 7rem;
    height: 1.875rem;
    cursor: pointer;
    font-size: 1.25rem;
}

.agentSignatureLabel {
    background-color: #f8f53b;
}

.agentSignatureContainer {
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.submitFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    width: 85%;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.policies {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.submitFormContainer button {
    margin-left: 1.375rem;
    margin-top: 2rem;
    width: 6.25rem;
    padding: 0.625rem;
    background-color: #4681f4;
    color: #fff ;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
}

.submitFormContainer button:disabled {
    cursor: not-allowed;
    background-color: #789ce6; 
}


@media screen and (max-width: 1368px) and (max-height: 800px)
{
    .formContent h1 {
        font-size: 1.3rem;
        text-align: center;
    }

    .formContent {
        font-size: 0.8rem;
        margin: 20rem 16rem;
    }

    .formContent h2 {
        font-size: 0.8rem;
    }

    .agentSignatureContainer {
        font-size: 0.8rem;
    }

    .agentSignatureLabel {
        font-size: 1.1rem;
    }
}

@media (max-width:470px) 
{
    .form {
        background-size: 75%;
        height: 110vh;
    }

    .formContent h1 {
        font-size: 0.8rem;
        text-align: center;
    }

    .formContent {
        font-size: 0.63rem;
        margin: 11rem 13rem;
    }

    .formContent h2 {
        font-size: 0.63rem;
    }

    .agentSignatureContainer {
        font-size: 0.63rem;
    }

    .agentSignatureLabel {
        font-size: 0.7rem;
    }
}
