.signed-container {
    background-color: #fff;
    height: 100vh;
}

.signed-container h1 {
    color: black;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;
}